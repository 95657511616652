




















import CmsHorizontalCardComponent from './CmsHorizontalCardComponent';
export default CmsHorizontalCardComponent;
